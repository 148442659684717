import React, { useRef, Suspense, useDeferredValue } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Grid, Center, Environment, ContactShadows, PresentationControls, useGLTF, Loader } from "@react-three/drei";


const exampleModels = [
  "demo_patientdentition2.glb",
  "demo_patientdentition3.glb",
  "demo_patientdentition4.glb",
  "demo_patientdentition1.glb",
  "demo_patientdentition5.glb",
  "demo_patientdentition6.glb",
]

function Teeth(props) {
  const ref = useRef()
  const gltfPath = useDeferredValue(exampleModels[props.indexName])
  const model = useGLTF(gltfPath)

  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    //ref.current.rotation.set(Math.cos(t / 4) / 8, Math.sin(t / 4) / 8, -0.2 - (1 + Math.sin(t / 1.5)) / 20)
    ref.current.rotation.set(0, Math.sin(t / 4) / 4, 0)
    //ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10
  })
  return (
    <group
      ref={ref}>
      <primitive object={model.scene} />
    </group>

  )
}


export default function SingleModelView(props) {

  return (
    <>
      <Canvas
        style={{
          height: "100%",
          width: "100%",
          background: "#303035",
          touchAction: "none",
        }}
        className={"draggable"}
        camera={{ fov: 35, position: [0, 0.25, 2.15] }}>

        <Grid
          position={[0, -0.5, 0]}
          infiniteGrid={false}
          fadeDistance={7}
          fadeStrength={1}
          cellSize={0.5}
          sectionSize={1}
          sectionColor={'#5a506e'}
          cellColor={'#6f6f6f'}
          cellThickness={1}
          sectionThickness={2}
          args={[10, 10]}
        />

        <Environment
          preset="warehouse"
          environmentIntensity={0} />
        <ContactShadows
          position={[0, -0.51, 0]}
          opacity={0.15}
          scale={3}
          blur={1.5} />

        <pointLight intensity={0.1} position={[10, 10, 5]} />
        <pointLight intensity={0.1} position={[-10, -10, -5]} />

        <PresentationControls
          global={true}
          snap={{ mass: 4, tension: 30 }}
          speed={2}
          polar={[-Math.PI / 2, Math.PI / 2]}
          azimuth={[-Infinity, Infinity]}
        >
          <Center>
            <Suspense fallback={null} >
              <Teeth indexName={props.keyName} />
            </Suspense>
          </Center>
        </PresentationControls>

      </Canvas>
      <Loader innerStyles={{background: '#9f7aea' }} containerStyles={{background: '#303035'}} />
    </>
  );
}

useGLTF.preload("demo_patientdentition1.glb")
useGLTF.preload("demo_patientdentition2.glb")
useGLTF.preload("demo_patientdentition3.glb")
useGLTF.preload("demo_patientdentition4.glb")
useGLTF.preload("demo_patientdentition5.glb")
useGLTF.preload("demo_patientdentition6.glb")