import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

// import Header from "../headers/light.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";

// import DesignIllustration from "../../images/design-illustration-2.svg";
// import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
// import { SingleModelView } from "SingleModelView.js";
// import { Suspense } from "react";


//pointer event turned off so use can interact with the teeth
const Container = tw.div`relative pointer-events-none`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 md:py-24 md:pb-96 pb-[440px]`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-0 lg:mt-12 flex-1 flex flex-col justify-center lg:self-end h-full`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-gray-400`;

const ButtonDesktop = tw.button`hidden sm:flex w-full sm:absolute right-0 top-0 bottom-0 border-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none hover:bg-primary-900 transition duration-300`;
const ButtonMobile = tw.button`flex sm:hidden w-full sm:absolute right-0 top-0 bottom-0 border-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none hover:bg-primary-900 transition duration-300`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 pointer-events-auto`}

`;

// const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
// `;

// const CustomersLogoStrip = styled.div`
//   ${tw`mt-12 lg:mt-20`}
//   p {
//     ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
//   }
//   img {
//     ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
//   }
// `;

// eslint-disable-next-line no-empty-pattern
export default ({ onButtonClick }) => {
  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} /> */}
      <Container>
        <TwoColumn>
          <RightColumn>
            <Heading>
              <span tw="text-purple-500">Engage your clients with</span> <span tw="text-gray-200">3D Visuals.</span>
            </Heading>
            <Paragraph>
              Our service is easy to setup, understand, and customize. Automatically transform your dental data into an intuative 3D space.
            </Paragraph>
            <Actions>
                {/* <input type="text" placeholder="Your E-mail Address" /> */}
                <ButtonDesktop onClick={onButtonClick}> Click Here </ButtonDesktop>
                <ButtonMobile onClick={onButtonClick}> Tap Here </ButtonMobile>
              </Actions>
          </RightColumn>
          <LeftColumn>
          </LeftColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container >
    </>
  );
};
