import React from "react";
import { createRoot } from 'react-dom/client';
// import ReactDOM from "react-dom";
import App from "./App";
// import Modal from "react-modal";

// Modal.setAppElement("#root");

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );

createRoot(document.getElementById('root')).render(
  <>
  <App />
  </>
)
